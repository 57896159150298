/* eslint-disable multiline-ternary */
/* eslint-disable indent */
import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { BlogLayout, Content } from 'layouts';
import { SEO } from 'components';
import { ArticleHeader, SimilarPostsLegacyContainer } from 'components/blog';
import '../styles/prism';

const formatDate = (date) => {
  return dayjs(date).format('MMMM D, YYYY');
};

const BlogContainer = styled.div`
  background-color: ${props => props.theme.colors.gray.lightest}; 
  margin: 0 auto;
  padding: 4rem 6rem 4rem 8rem;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-column-gap: 4rem;
  @media (max-width: ${props => props.theme.breakpoints.ginormous}) {
    padding: 4rem 3rem 4rem 5rem;
    grid-column-gap: 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xxl}) {
    padding: 4rem 3rem 4rem 4rem;
    grid-column-gap: 3rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    padding: 4rem 2rem;
    grid-column-gap: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    grid-template-columns: auto;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 4rem 1rem;
  }
`;

const ContentContainer = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  img, video {
    width: 100%;
    box-shadow: ${props => props.theme.shadow.image};
  }
  h3 {
    font-size: 1.3rem;
    font-weight: lighter;
  }
  .badge badge-pill, .badge-secondary {
    margin: 25px 15px 0 0 !important;
    color: ${props => props.theme.colors.black.base};
    background-color: ${props => props.theme.colors.white.white};
    border: 2px solid ${props => props.theme.colors.blue.base};
    border-radius: 3px;
  }
  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    padding: 2rem 1rem;
    max-width: 80%;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    max-width: 90%;
    img, video {
      max-width: 100%;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
    margin: 0 auto;
    padding: 0;
    }
  }
`;

const SuggestionBar = styled.div`
  background: ${props => props.theme.colors.white.light};
`;

const Post = ({ data, pageContext, location }) => {
  const { html, frontmatter, excerpt, fields } = data.markdownRemark;
  const { author, title, category, description } = frontmatter;
  const imagesrc = data.markdownRemark.frontmatter.image;
  const slug = fields.slug;

  const profilePath = '/assets/images/profile';
  const authorImage = author === 'Abner Germanow' ? `${profilePath}/abner.germanow.jpg`
                    : author === 'Anna Spysz' ? `${profilePath}/anna.spysz.jpg`
                    : author === 'Anna Yovandich' ? `${profilePath}/anna.yovandich.jpg`
                    : author === 'Apurva Jantrania' ? `${profilePath}/apurva.jantrania.jpg`
                    : author === 'Ashley Brooks' ? `${profilePath}/ashley.brooks.jpg`
                    : author === 'Chase Douglas' ? `${profilePath}/chase.douglas.jpg`
                    : author === 'Danielle Heberling' ? `${profilePath}/danielle.heberling.jpg`
                    : author === 'Farrah Campbell' ? `${profilePath}/farrah.campbell.jpg`
                    : author === 'Garrett Gillas' ? `${profilePath}/garrett.gillas.jpg`
                    : author === 'Gracie Gregory' ? `${profilePath}/gracie.gregory.jpg`
                    : author === 'Jun Fritz' ? `${profilePath}/jun.fritz.jpg`
                    : author === 'Matthew Bradburn' ? `${profilePath}/matthew.bradburn.jpg`
                    : author === 'Michelle Levine' ? `${profilePath}/michelle.levine.jpg`
                    : author === 'Nate Taggart' ? `${profilePath}/nate.taggart.jpg`
                    : author === 'Nica Fee' ? `${profilePath}/nica.fee.jpg`
                    : author === 'Nicole Blystone' ? `${profilePath}/nicole.blystone.jpg`
                    : author === 'Nuatu Tseggai' ? `${profilePath}/nuatu.tseggai.jpg`
                    : author === 'Ryan Coleman' ? `${profilePath}/ryan.coleman.jpg`
                    : author === 'Sam Goldstein' ? `${profilePath}/sam.goldstein.jpg`
                    : author === 'Stephanie Baum' ? `${profilePath}/stephanie.baum.jpg`
                    : author === 'Susan Little' ? `${profilePath}/susan.little.jpg`
                    : author === 'Ted' ? `${profilePath}/ted.jpg`
                    : author === 'Tim Wagner' ? `${profilePath}/tim.wagner.jpg`
                    : author === 'Tim Zonca' ? `${profilePath}/tim.zonca.jpg`
                    : `${profilePath}/team.stackery.jpg`;

  return (
    <BlogLayout location={location}>
      <SEO
        title={title}
        description={description || excerpt || ' '}
        banner={`https://www.stackery.io${imagesrc}`}
        author={author}
        pathname={slug}
        article={html}
      />
      <BlogContainer>
        <ContentContainer>
          <div>
            <ArticleHeader path={slug} title={title} author={author} authorImage={authorImage} date={formatDate(fields.date)} readtime={fields.readingTime.text} cover={imagesrc} tags={category} />
            <Content richTextContent={html} />
          </div>
        </ContentContainer>
      </BlogContainer>
      <SuggestionBar>
        <SimilarPostsLegacyContainer categories={category} currentPostSlug={slug} />
      </SuggestionBar>
    </BlogLayout>
  );
};

export default Post;

Post.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object
  }).isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object
};

export const query = graphql`
  query(
    $slug: String!
    ) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
        fields {
          slug
          date
          readingTime {
            text
          }
        }
        frontmatter {
          category
          author
          title
          twitter
          image
          description
        }
        html
        timeToRead
      }
    }
`;
